import Routes from "./Routes";
import "./App.css";
import "./assets/css/pages/error/error-5.css";
// import "./assets/css/custom.css";
import "./assets/css/style.bundle.css";
import NameState from "./pages/NameState";

const App = () => (
  <NameState>
    <Routes />;
  </NameState>
);

export default App;
