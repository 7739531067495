const GET_NAME = "GET_NAME";

const NameReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_NAME:
      return {
        ...state,
        name: true,
        link: payload,
      };
    default:
      return state;
  }
};

export default NameReducer;
