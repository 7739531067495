import NameContext from "./Context";
import { useReducer } from "react";
import NameReducer from "./NameReducer";

const GET_NAME = "GET_NAME";

const NameState = (props) => {
  const INITIALSTATE = {
    name: false,
    link: "",
  };

  const [state, dispatch] = useReducer(NameReducer, INITIALSTATE);

  // Get Url Name
  const getUrlName = ({ history, link }) => {
    dispatch({
      type: GET_NAME,
      payload: link,
    });
    history.push("/docs");
  };
  return (
    <NameContext.Provider
      value={{
        name: state.name,
        link: state.link,
        getUrlName,
      }}
    >
      {props.children}
    </NameContext.Provider>
  );
};

export default NameState;
