import { useEffect, useCallback, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import NameContext from "./Context";

const url = "https://omsapi.pra.insure/api/chkUrlName_ExpireDate";

const Spinner = () => {
  const nameContext = useContext(NameContext);
  const { getUrlName } = nameContext;

  const { name } = useParams();
  const history = useHistory();

  const checkName = useCallback(async () => {
    try {
      const res = await axios.post(`${url}`, { name });
      if (res.data.success) {
        getUrlName({ link: name, history });
      }
    } catch (err) {
      history.push("/notfound");
    }
  }, [name, history, getUrlName]);

  useEffect(() => {
    checkName();
  }, [checkName]);

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <div class="spinner spinner-primary spinner-lg mr-15"></div>
    </div>
  );
};

export default Spinner;
