import { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import NameContext from "./Context";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const nameContext = useContext(NameContext);

  const { name } = nameContext;

  return (
    <Route
      {...rest}
      render={(props) =>
        !name ? <Redirect to="/notfound" /> : <Component {...props} />
      }
    />
  );
};

export default ProtectedRoute;
