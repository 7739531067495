import { Route, Switch } from "react-router-dom";

import Docs from "./pages/Docs";
import NotFound from "./pages/404";

import Spinner from "./pages/Spinner";
import ProtectedRoute from "./pages/ProtectedRoute";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/doc/:name" component={Spinner} />
      <ProtectedRoute exact path="/docs" component={Docs} />
      <Route exact path="/notfound" component={NotFound} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
