const NotFound = () => {
  return (
    <div className="header-fixed header-mobile-fixed subheader-enabled subheader-fixed aside-enabled aside-fixed aside-minimize-hoverable page-loading">
      <div className="d-flex flex-column flex-root">
        <div className="error error-5 d-flex flex-row-fluid bgi-size-cover bgi-position-center">
          <div className="container d-flex flex-row-fluid flex-column justify-content-md-center p-12">
            <h1 className="error-title font-weight-boldest text-info mt-10 mt-md-0 mb-12">
              Oops!
            </h1>
            <p className="font-weight-boldest display-4">
              Something went wrong here.
            </p>
            <p className="font-size-h3">
              It's seems that this is an invalid url or its has expired. Please
              contact the appropriate center for help.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
