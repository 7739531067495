import { useState, useEffect, useContext, useCallback } from "react";
import axios from "axios";
import NameContext from "./Context";

const url = "https://omsapi.pra.insure/api/ListStorage";

const Docs = () => {
  const [docs, setDocs] = useState([]);
  const [loading, setLoading] = useState(false);

  const nameContext = useContext(NameContext);
  const { link } = nameContext;

  const submitRequest = useCallback(async () => {
    try {
      setLoading(true);

      const res = await axios.post(`${url}`, { link });
      setDocs(res.data);

      setLoading(false);
    } catch (err) {
      console.error(err.response.data.errors);
      setLoading(false);
    }
  }, [link]);

  useEffect(() => {
    submitRequest();
  }, [submitRequest]);

  return (
    <>
      <div
        className="content d-flex flex-column flex-column-fluid"
        id="kt_content"
      >
        <div className="d-flex flex-column-fluid">
          <div className="container">
            <div className="d-flex flex-row mx-auto">
              <div className="flex-row-fluid">
                <div className="col-md-7 col-lg-12 col-xxl-12 mx-auto">
                  <div className="card card-custom card-stretch gutter-b">
                    <div className="card-body p-xxl-10 pb-20">
                      <div className="row mb-17">
                        <div className="col-xxl-12 mb-11 mb-xxl-0">
                          <div className="rounded d-flex align-items-center justify-content-center">
                            <div className="col-xxl-12 pl-xxl-5">
                              <h3 className="font-weight-bolder pb-5">
                                Certificates
                              </h3>
                              {loading ? (
                                <div className="d-flex justify-content-center align-items-center">
                                  <div className="spinner spinner-primary spinner-lg mr-15"></div>
                                </div>
                              ) : docs.length > 0 ? (
                                <table className="table table-bordered table-striped">
                                  <thead>
                                    <tr>
                                      <th style={{ width: "7%" }}></th>
                                      <th>FileName</th>
                                      <th style={{ width: "16%" }}>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {docs.map((doc) => (
                                      <tr key={doc.id} className="">
                                        <td>
                                          <i className="far fa-file-alt text-primary fa-2x"></i>
                                        </td>
                                        <td>{doc.name}</td>
                                        <td>
                                          <div className="d-flex">
                                            <a
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              href={doc.file}
                                              data-rh="Download Files"
                                              className="btn btn-primary btn-sm m-1 shadow"
                                            >
                                              Download
                                            </a>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              ) : (
                                <div className="pt-10 px-5">
                                  <div className="alert alert-warning text-center">
                                    No data yet
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Docs;
